var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.loading)?_c('div',{staticClass:"col-md-12",staticStyle:{"text-align":"center"}},[_c('div',{class:[
      {
        'spinner-grow': _vm.type === 'grow',
        'spinner-border': _vm.type === 'border',
        'spinner-grow-lg': _vm.type === 'grow' && _vm.size === 'lg',
        'spinner-border-lg': _vm.type === 'border' && _vm.size === 'lg',
      },
      `text-${_vm.color}`,
    ],attrs:{"role":"status"}},[_c('span',{staticClass:"sr-only"},[_vm._v("Carregando...")])])]):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }