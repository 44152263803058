import Vue from 'vue';
import Vuex from 'vuex';
import state from './state';
import mutations from './mutations';
import * as actions from './actions';
import * as getters from './getters';

import { store as auth } from '../pages/auth';
import { store as companies } from '../pages/companies';
import { store as franchisees } from '../pages/franchisees';
import { store as categories } from '../pages/categories';
import { store as cities } from '../pages/cities';
import { store as users } from '../pages/users';
import { store as typePayments } from '../pages/type-payments';
import { store as recipients } from '../pages/recipients';

Vue.use(Vuex);

export default new Vuex.Store({
  state,
  mutations,
  actions,
  getters,
  modules: {
    auth,
    companies,
    franchisees,
    categories,
    cities,
    users,
    typePayments,
    recipients,
  },
});
