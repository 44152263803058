<script>
import http from '~/http';

export default {
  name: 'NavBar',

  computed: {
    user() {
      return this.$store.state.auth.user;
    },
  },

  methods: {
    logout() {
      http.post('auth/logout').then(({ data }) => {
        this.$alert.show('', data.message, 'success');
        this.$store.dispatch('logout');
        this.$router.push({ name: 'login' });
      });
    },
  },
};
</script>

<template>
  <nav class="main-header navbar navbar-expand navbar-white navbar-light">
    <ul class="navbar-nav">
      <li class="nav-item">
        <a class="nav-link" data-widget="pushmenu" href="#" role="button">
          <i class="fas fa-bars"></i>
        </a>
      </li>
    </ul>
    <!-- Right navbar links -->
    <ul class="navbar-nav ml-auto">
      <li class="nav-item dropdown user-menu">
        <a href="#" class="nav-link dropdown-toggle" data-toggle="dropdown">
          <img
            :src="user.avatar || '/images/no-avatar.jpg'"
            class="user-image img-circle elevation-2"
            alt="User Image"
          />
          <span class="d-none d-md-inline">{{ user.name }}</span>
        </a>
        <ul class="dropdown-menu dropdown-menu-lg dropdown-menu-right">
          <!-- User image -->
          <li class="user-header bg-primary">
            <img
              :src="user.avatar || '/images/no-avatar.jpg'"
              class="img-circle elevation-2"
              :alt="user.name"
            />

            <p>
              {{ user.name }}
            </p>
          </li>
          <!-- Menu Footer-->
          <li class="user-footer">
            <a href="#" class="btn btn-default btn-flat">Alterar perfil</a>
            <a href="#" @click.prevent="logout" class="btn btn-default btn-flat float-right">
              Sair</a
            >
          </li>
        </ul>
      </li>
    </ul>
  </nav>
</template>
