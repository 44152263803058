const Index = () => import('./components/index');

export default [
  {
    path: '/orders',
    name: 'orders.index',
    meta: {
      title: 'Pedidos',
      shield: 'orders.index',
    },
    component: Index,
  },
];
