const Login = () => import('./login');
// const RecoveryPassword = () => import('./components/recovery-password.vue');
// const SelectCompany = () => import('./components/select-company.vue');

export default [
  {
    path: '/login',
    name: 'login',
    meta: {
      layout: 'clean',
      title: 'Login',
    },
    component: Login,
  },
  // {
  //   path: '/recuperar-senha',
  //   name: 'recovery.password',
  //   meta: {
  //     layout: 'clean',
  //     title: 'Recuperar senha',
  //   },
  //   component: RecoveryPassword,
  // },
];
