import * as types from './mutations-types';
import {
  localStorageSetItem,
  localStorageGetItem,
  localStorageRemoveItem,
} from '~/shared/utils/local-storage';

export const setAuth = (context, payload) => {
  context.commit(types.SET_TOKEN, payload.token);
  context.commit(types.SET_USER, payload.user);
  context.commit(types.SET_PERMISSIONS, payload.permissions);

  localStorageSetItem('token', payload.token);
  localStorageSetItem('user', payload.user);
};

export const setDataFromLocalStorage = context => {
  context.commit(types.SET_TOKEN, localStorageGetItem('token'));
  context.commit(types.SET_USER, localStorageGetItem('user'));
};

export const updateDataAuth = (context, payload) => {
  context.commit(types.SET_USER, payload.user);
  context.commit(types.SET_PERMISSIONS, payload.permissions);
};

export const logout = context => {
  context.commit(types.SET_TOKEN, null);
  context.commit(types.SET_USER, {});
  context.commit(types.SET_PERMISSIONS, []);

  localStorageRemoveItem('token');
  localStorageRemoveItem('user');
  localStorageRemoveItem('permissions');
};
