/* eslint-disable no-underscore-dangle */
/* eslint-disable no-param-reassign */
import Vue from 'vue';
import http from '~/http';
import store from '~/store';
import { errorsApiFormat } from '~/shared/utils';

http.interceptors.request.use(
  response => {
    const { token } = store.getters;

    if (token && token !== '') {
      response.headers.common.Authorization = `Bearer ${token}`;
      response.headers.common.timezone = window.timezone;
    }

    return response;
  },

  error => Promise.reject(error),
);

http.interceptors.response.use(
  response => response,
  async error => {
    if (error.response?.data?.message === 'The token has been blacklisted') {
      store.dispatch('logout');
      window.location = '/';
      return Promise.reject(error);
    }

    if (error.response.status === 422) {
      Vue.prototype.$alert.show('Oops!', errorsApiFormat(error), 'warning');
      return Promise.reject(error);
    }

    if (error.response.status === 400 || error.response.status === 421) {
      Vue.prototype.$alert.show('Oops!', errorsApiFormat(error), 'error');
      return Promise.reject(error);
    }

    if (error.response.status >= 500) {
      Vue.prototype.$alert.show(
        'Oops!',
        'Houve um erro interno no servidor. Já notificamos o a equipe! Tente novamente mais tarde.',
        'error',
      );
      return Promise.reject(error);
    }

    if (error.response.status === 401) {
      store.dispatch('logout');
      window.location = '/';
    }

    return Promise.reject(error);

    // let errorResponse = error.response

    // if (errorResponse.status === 421 || errorResponse.status === 420) {
    //   Vue.prototype.$alert.show('', errorResponse.data.message, 'warning')
    // }

    // if (errorResponse.status === 401) {
    //   return postRefreshToken()
    //     .then(response => {
    //       store.dispatch('refreshToken', response.data)
    //       errorResponse.config.headers['Authorization'] = 'Bearer ' + response.data.access_token
    //       return http(errorResponse.config)
    //     }).catch(error => {
    //       store.dispatch('directLogout')
    //       window.location = '/'
    //       return Promise.reject(error)
    //     })
    // }
    // return Promise.reject(error)
  },
);
