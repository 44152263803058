import checkPermission from './checkPermission';

const install = Vue => {
  Object.defineProperty(Vue.prototype, '$acl', {
    get() {
      return checkPermission(this);
    },
  });
};

export default { install };
