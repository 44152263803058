const Index = () => import('./main');

export default [
  {
    path: '/type-payments',
    name: 'type-payments.index',
    meta: {
      title: 'Tipos de pagamentos',
      shield: 'type-payments.index',
    },
    component: Index,
  },
];
