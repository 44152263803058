export const initialState = () => ({
  loading: false,
  query: {
    search: '',
    page: 1,
  },
  list: [],
  paginationData: {},
});

export default {
  ...initialState(),
};
