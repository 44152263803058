<script>
/* eslint-disable max-len */
export default {
  name: 'Button',
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    loadingColor: {
      type: String,
    },
    type: {
      type: String,
      default: 'button',
    },
    message: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      color: this.loadingColor,
    };
  },
  methods: {
    callback(e) {
      this.$emit('click', e);
    },
  },
  mounted() {
    if (!this.loadingColor) {
      if (this.$el.classList.contains('btn-primary')) {
        this.color = '#fff';
      } else {
        this.color = '#ff6600';
      }
    }
  },
};
</script>

<template>
  <button :type="type" @click="callback($event)" :disabled="loading" class="btn">
    <slot v-if="!loading"></slot>
    <!-- By Sam Herbert (@sherb), for everyone. More @ http://goo.gl/7AJzbL -->
    <svg
      v-if="loading"
      xmlns:svg="http://www.w3.org/2000/svg"
      xmlns="http://www.w3.org/2000/svg"
      xmlns:xlink="http://www.w3.org/1999/xlink"
      version="1.0"
      width="22px"
      height="22px"
      viewBox="0 0 128 128"
      xml:space="preserve"
    >
      <g>
        <path
          d="M75.4 126.63a11.43 11.43 0 0 1-2.1-22.65 40.9 40.9 0 0 0 30.5-30.6 11.4 11.4 0 1 1 22.27 4.87h.02a63.77 63.77 0 0 1-47.8 48.05v-.02a11.38 11.38 0 0 1-2.93.37z"
          :fill="color"
          fill-opacity="1"
        />
        <animateTransform
          attributeName="transform"
          type="rotate"
          from="0 64 64"
          to="360 64 64"
          dur="500ms"
          repeatCount="indefinite"
        ></animateTransform>
      </g>
    </svg>

    <span v-if="loading && message" class="ml-1" :style="`color: ${color}`">
      &nbsp; {{ message }}
    </span>
  </button>
</template>
