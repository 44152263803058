const Index = () => import('./components/index');
const Create = () => import('./components/create');
const Edit = () => import('./components/edit');
const EditBank = () => import('./components/edit-bank');

export default [
  {
    path: '/recipients',
    name: 'recipients.index',
    meta: {
      title: 'Recebedores',
      shield: 'recipients.index',
    },
    component: Index,
  },
  {
    path: '/recipients/create',
    name: 'recipients.create',
    meta: {
      title: 'Adicionar recebedor',
      shield: 'recipients.create',
    },
    component: Create,
  },
  {
    path: '/recipients/:id/edit',
    name: 'recipients.edit',
    meta: {
      title: 'Editar recebedor',
      shield: 'recipients.edit',
    },
    component: Edit,
  },
  {
    path: '/recipients/:id/edit/bank',
    name: 'recipients.edit.bank',
    meta: {
      title: 'Editar banco de recebedor',
      shield: 'recipients.edit.bank',
    },
    component: EditBank,
  },
];
