<script>
export default {
  name: 'CardDashboard',

  props: {
    value: { required: true },
    title: { required: true },
    link: { default: null },
    icon: { default: null },
    bg: { default: null },
  },
};
</script>

<template>
  <div class="col-12 col-sm-6 col-md-3">
    <div class="info-box">
      <span :class="bg" class="info-box-icon elevation-1">
        <i :class="icon" class="fas"></i>
      </span>

      <div class="info-box-content">
        <span class="info-box-text">{{ title }}</span>
        <span class="info-box-number">
          {{ value ? value : value === 0 ? '0' : '...' }}
        </span>
      </div>
    </div>
  </div>
</template>
