<script>
export default {
  name: 'CardDashboard',

  props: {
    value: { required: true },
    title: { required: true },
    linkText: { default: null },
    linkPress: { default: null },
    icon: { default: null },
    bg: { default: null },
  },
};
</script>

<template>
  <div class="col-md-3 col-6">
    <div class="small-box" :class="bg">
      <div class="inner">
        <h3>{{ value ? value : value === 0 ? '0' : '...' }}</h3>

        <p>{{ title }}</p>
      </div>
      <div class="icon">
        <i class="fa" :class="icon"></i>
      </div>
      <a href="#" v-if="linkText" @click.prevent="linkPress" class="small-box-footer">
        {{ linkText }} <i class="fas fa-arrow-circle-right"></i>
      </a>
    </div>
  </div>
</template>
