export const initialState = () => ({
  loading: false,
  query: {
    search: '',
    page: 1,
  },
  companies: {
    data: [],
    total: 0,
  },
});

export default {
  ...initialState(),
};
