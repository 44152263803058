<script>
export default {
  props: {
    status: { type: String, required: true },
  },

  data() {
    return {
      text: '',
      color: '',
    };
  },

  mounted() {
    let text;
    switch (this.status) {
      case 'processing':
        text = 'em aberto';
        this.color = 'warning';
        break;
      case 'authorized':
        text = 'autorizado';
        this.color = 'info';
        break;
      case 'paid':
        text = 'pago';
        this.color = 'success';
        break;
      case 'refunded':
        text = 'estornado';
        this.color = 'info';
        break;
      case 'waiting_payment':
        text = 'aguardando pagamento';
        this.color = 'info';
        break;
      case 'pending_refund':
        text = 'pendente de estorno';
        this.color = 'info';
        break;
      case 'refused':
        text = 'recusado';
        this.color = 'danger';
        break;
      case 'chargedback':
        text = 'fatura em contestação';
        this.color = 'danger';
        break;
      case 'analyzing':
        text = 'em análise';
        this.color = 'warning';
        break;
      case 'pending_review':
        text = 'pendente de revisão manual';
        this.color = 'info';
        break;
      case 'due':
        text = 'vencido';
        this.color = 'danger';
        break;

      default:
        text = '';
    }

    this.text = text;
  },
};
</script>

<template>
  <span :class="`badge badge-${this.color}`">{{ text }}</span>
</template>
