const Index = () => import('./main');

export default [
  {
    path: '/cities',
    name: 'cities.index',
    meta: {
      title: 'Cidades',
      shield: 'cities.index',
    },
    component: Index,
  },
];
