import { routes as dashboardRoutes } from '~/pages/dashboard';
import { routes as authRoutes } from '~/pages/auth';
import { routes as companiesRoutes } from '~/pages/companies';
import { routes as franchiseesRoutes } from '~/pages/franchisees';
import { routes as categoriesRoutes } from '~/pages/categories';
import { routes as citiesRoutes } from '~/pages/cities';
import { routes as usersRoutes } from '~/pages/users';
import { routes as typePaymentsRoutes } from '~/pages/type-payments';
import { routes as recipientsRoutes } from '~/pages/recipients';
import { routes as termsPrivacyRoutes } from '~/pages/terms-privacy';
import { routes as ordersRoutes } from '~/pages/orders';

const rootRoutes = [
  {
    path: '/',
    name: 'home',
    redirect: '/dashboard',
  },
];

export default [
  ...rootRoutes,
  ...dashboardRoutes,
  ...authRoutes,
  ...companiesRoutes,
  ...franchiseesRoutes,
  ...categoriesRoutes,
  ...citiesRoutes,
  ...usersRoutes,
  ...typePaymentsRoutes,
  ...recipientsRoutes,
  ...termsPrivacyRoutes,
  ...ordersRoutes,
];
