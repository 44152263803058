import store from '~/store';
import checkPermission from '~/shared/acl/check';

const isAuthRoute = route => route.path.indexOf('/login') !== -1;
const isAuthPassword = route => route.path.indexOf('/recuperar-senha') !== -1;
const isLogged = () => store.getters.isLogged;

export default async (to, from, next) => {
  if (!isLogged()) {
    store.dispatch('setDataFromLocalStorage');
  }

  if (isLogged()) {
    if (to.meta.shield) {
      const { permissions } = store.getters;

      if (!checkPermission(to.meta.shield, permissions)) {
        next({
          name: 'dashboard',
        });
        return;
      }
    }

    if (isAuthRoute(to) || isAuthPassword(to)) {
      next({
        name: 'dashboard',
      });
      return;
    }
  }

  if (!isAuthRoute(to) && !isLogged() && !isAuthPassword(to)) {
    next({
      name: 'login',
    });
  } else {
    const { title } = to.meta;

    document.title = `${title ? `${title} - ` : ''} ${process.env.VUE_APP_NAME}`;
    next();
  }
};
