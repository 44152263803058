import date from './date';
import money from './money';
import cpfCnpj from './cpf_cnpj';
import phone from './phone';
import cep from './cep';

export default {
  date,
  money,
  cpfCnpj,
  phone,
  cep,
};
