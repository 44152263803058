import Vue from 'vue';
import Button from './Button.vue';
import Loading from './Loading.vue';
import Pagination from './Pagination.vue';
import DateTimeRange from './DateTimeRange.vue';
import Input from './Input.vue';
import NewInput from '@bora-delivery/shared/components/Input';
import InputFile from './InputFile.vue';
import Select2 from './Select2.vue';
import Select2Ajax from './Select2Ajax.vue';
import StatusInvoice from './StatusInvoice.vue';
import Modal from '@bora-delivery/shared/components/Modal';
import Card from './Card.vue';
import CardLink from './CardLink.vue';
import Table from '@bora-delivery/shared/components/Table';
import Checkbox from '@bora-delivery/shared/components/Checkbox';
import DateTime from '@bora-delivery/shared/components/DateTime';

Vue.component('BoraButton', Button);
Vue.component('BoraLoading', Loading);
Vue.component('BoraPagination', Pagination);
Vue.component('BoraDateTimeRange', DateTimeRange);
Vue.component('BoraInput', Input);
Vue.component('BoraInputFile', InputFile);
Vue.component('BoraSelect2', Select2);
Vue.component('BoraSelect2Ajax', Select2Ajax);
Vue.component('BoraStatusInvoice', StatusInvoice);
Vue.component('BoraModal', Modal);
Vue.component('BoraCard', Card);
Vue.component('BoraCardLink', CardLink);
Vue.component('BoraTable', Table);
Vue.component('BoraCheckbox', Checkbox);
Vue.component('BoraNewInput', NewInput);
Vue.component('BoraDateTime', DateTime);
