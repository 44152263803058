const errorsApiFormat = error => {
  let errors = '';
  if (error.response.data.message) {
    errors = error.response.data.message;
  } else if (error.response.data.errors) {
    const errorsResponse = error.response.data.errors;
    Object.keys(errorsResponse).forEach(error => {
      errors += `${errorsResponse[error][0]}<br>`;
    });
  } else if (error.response.data) {
    const errorsResponse = error.response.data;
    Object.keys(errorsResponse).forEach(error => {
      errors += `${errorsResponse[error]}<br>`;
    });
  } else {
    error.response.data.forEach(error => {
      errors += `${error.message}<br>`;
    });
  }
  return errors;
};

export default errorsApiFormat;
