const Index = () => import('./main');

export default [
  {
    path: '/categories',
    name: 'categories.index',
    meta: {
      title: 'Categorias',
      shield: 'categories.index',
    },
    component: Index,
  },
];
