<script>
import { mapGetters } from 'vuex';
import NavBar from './app/nav-bar.vue';
import Aside from './app/aside.vue';
import Footer from './app/footer.vue';

export default {
  name: 'AppLayout',

  components: {
    NavBar,
    Aside,
    Footer,
  },

  computed: {
    ...mapGetters(['isLogged']),

    layout() {
      return `${this.$route.meta.layout || 'app'}-layout`;
    },
  },
};
</script>

<template>
  <div class="wrapper" v-if="isLogged">
    <NavBar />
    <Aside />
    <slide-y-up-transition mode="out-in" origin="center top">
      <router-view></router-view>
    </slide-y-up-transition>
    <Footer />
  </div>
</template>
