import * as types from './mutation-types';
import http from '~/http';

export const loadData = (context, payload) => {
  context.commit(types.SET_QUERY, payload);
  context.dispatch('setLoading', true);

  http
    .get('cities', { params: context.state.query })
    .then(({ data }) => {
      context.commit(types.SET_LIST, data.data.data);

      const paginationData = data.data;
      delete paginationData.data;
      context.commit(types.SET_PAGINATION, paginationData);
    })
    .finally(() => {
      context.dispatch('setLoading', false);
    });
};

export const setQuery = (context, payload) => {
  context.commit(types.SET_QUERY, payload);
  context.dispatch('loadData');
};

export const resetQuery = context => {
  context.commit(types.RESET_QUERY);
  context.dispatch('loadData');
};

export const setLoading = (context, payload) => {
  context.commit(types.SET_LOADING, payload);
};
