import * as types from './mutation-types';
import { initialState } from './state';

export default {
  [types.SET_FRANCHISEES](state, payload) {
    state.franchisees = payload;
  },

  [types.EDIT_FRANCHISEE](state, payload) {
    state.franchisees.data = state.franchisees.data.map(item => {
      if (item.id === payload.id) {
        return { ...item, ...payload };
      }
      return item;
    });
  },

  [types.SET_QUERY](state, payload) {
    state.query = { ...state.query, ...payload };
  },

  [types.RESET_QUERY](state) {
    state.query = initialState().query;
  },

  [types.SET_LOADING](state, payload) {
    state.loading = payload;
  },
};
