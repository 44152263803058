const Index = () => import('./components/index');
const Edit = () => import('./components/edit');

export default [
  {
    path: '/terms-privacy',
    name: 'terms-privacy.index',
    meta: {
      title: 'Termos de uso e Políticas de Privacidade',
      shield: 'terms-privacy.index',
    },
    component: Index,
  },
  {
    path: '/terms-privacy/:id/edit',
    name: 'terms-privacy.edit',
    meta: {
      title: 'Alterar Termos de uso/Políticas de Privacidade',
      shield: 'terms-privacy.edit',
    },
    component: Edit,
  },
];
