const Index = () => import('./components/index');
const Show = () => import('./components/show');

export default [
  {
    path: '/companies',
    name: 'companies.index',
    meta: {
      title: 'Empresas',
      shield: 'companies.index',
    },
    component: Index,
  },
  {
    path: '/companies/:id',
    name: 'companies.show',
    meta: {
      title: 'Ver empresa',
      shield: 'companies.show',
    },
    component: Show,
  },
];
