import Vue from 'vue';

export default Vue.filter('phone', value => {
  let phone = value.toString();
  if (value) {
    if (phone.length === 11) {
      phone = `(${phone.substr(0, 2)}) ${phone.substr(2, 5)}-${phone.substr(7, 4)}`;
    } else if (phone.length === 10) {
      phone = `(${phone.substr(0, 2)}) ${phone.substr(2, 4)}-${phone.substr(6, 4)}`;
    } else if (phone.length === 14) {
      phone = `${phone.substr(0, 3)} (${phone.substr(3, 2)}) ${phone.substr(5, 5)}-${phone.substr(
        10,
        4,
      )}`;
    } else {
      phone = `${phone.substr(0, 3)} (${phone.substr(3, 2)}) ${phone.substr(5, 4)}-${phone.substr(
        9,
        4,
      )}`;
    }
  } else {
    phone = '-';
  }

  return phone;
});
