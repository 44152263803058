import * as types from './mutation-types';
import { initialState } from './state';

export default {
  [types.SET_COMPANIES](state, payload) {
    state.companies = payload;
  },

  [types.SET_QUERY](state, payload) {
    state.query = { ...state.query, ...payload };
  },

  [types.RESET_QUERY](state) {
    state.query = initialState().query;
  },

  [types.SET_LOADING](state, payload) {
    state.loading = payload;
  },
};
