import * as types from './mutation-types';
import http from '~/http';

export const loadFranchisees = (context, payload) => {
  context.commit(types.SET_QUERY, payload);
  context.dispatch('setLoading', true);
  http
    .get('franchisees', { params: context.state.query })
    .then(({ data }) => {
      context.commit(types.SET_FRANCHISEES, data.data);
    })
    .finally(() => {
      context.dispatch('setLoading', false);
    });
};

export const editFranchisee = (context, payload) => {
  context.commit(types.EDIT_FRANCHISEE, payload);
};

export const setQuery = (context, payload) => {
  context.commit(types.SET_QUERY, payload);
  context.dispatch('loadFranchisees');
};

export const resetQuery = context => {
  context.commit(types.RESET_QUERY);
  context.dispatch('loadFranchisees');
};

export const setLoading = (context, payload) => {
  context.commit(types.SET_LOADING, payload);
};
