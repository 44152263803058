const checkPermission = context => ({
  checkPermission(permission) {
    if (context.$store.getters.isLogged) {
      if (process.env.VUE_APP_ENABLE_SHIELDS === 'false') {
        return true;
      }

      if (typeof permission !== 'undefined') {
        const { permissions } = context.$store.getters;

        if (permission === '') return true;

        const p = permission.indexOf('|') !== -1 ? permission.split('|') : [permission];

        return (
          p.find(permission => {
            const needed = permission.indexOf('&') !== -1 ? permission.split('&') : permission;

            if (Array.isArray(needed)) {
              return needed.every(need => permissions.indexOf(need) !== -1);
            }
            return permissions.indexOf(needed) !== -1 ? 1 : 0;
          }) !== undefined
        );
      }
      return false;
    }

    return false;
  },
});

export default checkPermission;
