const Index = () => import('./components/index');
const Create = () => import('./components/create');
const Edit = () => import('./components/edit');
const Show = () => import('./components/show');

export default [
  {
    path: '/users',
    name: 'users.index',
    meta: {
      title: 'Usuários',
      shield: 'users.index',
    },
    component: Index,
  },
  {
    path: '/users/create',
    name: 'users.create',
    meta: {
      title: 'Adicionar usuário',
      shield: 'users.create',
    },
    component: Create,
  },
  {
    path: '/users/:id/edit',
    name: 'users.edit',
    meta: {
      title: 'Editar usuário',
      shield: 'users.edit',
    },
    component: Edit,
  },
  {
    path: '/users/:id',
    name: 'users.show',
    meta: {
      title: 'Ver usuário',
      shield: 'users.show',
    },
    component: Show,
  },
];
