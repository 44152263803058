import * as types from './mutation-types';
import { initialState } from './state';

export default {
  [types.SET_DATA](state, payload) {
    state.data = payload;
  },

  [types.EDIT_ITEM](state, payload) {
    state.data.data = state.data.data.map(item => {
      if (item.id === payload.id) {
        return {
          ...item,
          ...payload,
        };
      }

      return item;
    });
  },

  [types.SET_QUERY](state, payload) {
    state.query = { ...state.query, ...payload };
  },

  [types.RESET_QUERY](state) {
    state.query = initialState().query;
  },

  [types.SET_LOADING](state, payload) {
    state.loading = payload;
  },
};
