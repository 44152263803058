const Index = () => import('./components/index');
const Create = () => import('./components/create');
const Show = () => import('./components/show');
const ShowInvoice = () => import('./components/show/tabs/invoices/show');
const CreateInvoice = () => import('./components/invoices/create');
const EditInvoice = () => import('./components/invoices/edit');
const Edit = () => import('./components/edit');
const CreateSubAccount = () => import('./components/create-sub-account');

export default [
  {
    path: '/licensed',
    name: 'franchisees.index',
    meta: {
      title: 'Licenciados',
      shield: 'licensed.index',
    },
    component: Index,
  },
  {
    path: '/licensed/create',
    name: 'franchisees.create',
    meta: {
      title: 'Adicionar licenciado',
      shield: 'licensed.create',
    },
    component: Create,
  },
  {
    path: '/licensed/:id',
    name: 'franchisees.show',
    meta: {
      title: 'Ver licenciado',
      shield: 'licensed.show',
    },
    component: Show,
  },
  {
    path: '/licensed/:id/edit',
    name: 'franchisees.edit',
    meta: {
      title: 'Editar dados do licenciado',
      shield: 'licensed.edit',
    },
    component: Edit,
  },
  {
    path: '/licensed/:id/create-sub-account',
    name: 'franchisees.create.sub-account',
    meta: {
      title: 'Criar subconta do licenciado',
      shield: 'licensed.create.sub-account',
    },
    component: CreateSubAccount,
  },
  {
    path: '/licensed/:id/invoices/create',
    name: 'franchisees.invoices.create',
    meta: {
      title: 'Criar fatura',
      shield: 'licensed.invoices.create',
    },
    component: CreateInvoice,
  },
  {
    path: '/licensed/:id/invoices/:invoice_id',
    name: 'franchisees.invoices.show',
    meta: {
      title: 'Ver fatura',
      shield: 'licensed.invoices.show',
    },
    component: ShowInvoice,
  },
  {
    path: '/licensed/:id/invoices/:invoice_id/edit',
    name: 'franchisees.invoices.edit',
    meta: {
      title: 'Ver fatura',
      shield: 'licensed.invoices.edit',
    },
    component: EditInvoice,
  },
];
